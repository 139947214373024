import './amnesty.png';
import './cash.png';
import './dhlecommerce.jpg';
import './drive.png';
import './ems.png';
import './epacket.jpg';
import './eu.png';
import './favicon.ico';
import './favicon_dev.ico';
import './fedex.jpg';
import './invoice.png';
import './japan-post.svg';
import './jp.png';
import './loading-2.gif';
import './loading.gif';
import './mailchimp-square.png';
import './mailchimp.png';
import './manual.png';
import './msf.png';
import './paypal.png';
import './pdf-last-japan-post.png';
import './pdf-last-dhl.png';
import './placeholder.gif';
import './postal_parcel.png';
import './print.png';
import './receipt.png';
import './sagawa.svg';
import './seido-logo.png';
import './sheets.png';
import './shopify-banner.png';
import './shopify.png';
import './small_packet.png';
import './smbc.png';
import './stripe.png';
import './unicef.png';
import './unicef.png';
import './ups.png';
import './us.png';



